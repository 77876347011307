import {actionTypes} from './../actions/AccountInfoActions';

const AccountInfoReducer = (state = {}, action) => {
    const {payload, type} = action;
    switch(type) {
        case actionTypes.INIT_ACCOUNT_INFO:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};

export default AccountInfoReducer;