import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MemberTable from '../components/MemberTable';
import {getAxiosInstance} from "./../config/axios";
import { saveCenterVisit } from '../services/SaveVisit';
//import { getCookie } from '../utils/Cookies';
import moment from 'moment';
import environment from "../config/environment";
import { MUI_DATE_TIME_PICKER_FORMAT } from '../utils/MuiConstants';
import {connect} from 'react-redux';

import Alert from '../components/Alert'

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
        />
    );
}

const columns = [
    { id: 'firstName', label: 'First Name', minWidth: 120 },
    { id: 'lastName', label: 'Last Name', minWidth: 120 },
    {
        id: 'dob',
        label: 'DOB',
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'relationship',
        label: 'Relationship',
        minWidth: 60
    },
    {
        id: 'gender',
        label: 'Gender',
        minWidth: 20
    },
];

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    label: {
        margin: theme.spacing(1),
    },
    vrificationLabel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const VALID_MEMBER = 'Valid Member';
const INVALID_MEMBER = 'Invalid Member';
const INVALID_BY_DATE = 'Invalid Member, check Effective Date';
const INACTIVE_MEMBER = 'Innactive Member';
const ERROR_MSG = 'There was an error accessing the database please contact support';

const MembershipValidation = (props) => {

    const {accountInfo} = props;
    const classes = useStyles();
    const initialState = {
        membershipId: '',
        visitTypeId: '',
        comments: '',
        members: []
    };

    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(initialState);
    const [canSearch, setCanSearch] = useState(false);
    const [isValidMember, setIsValidMember] = useState(false);
    const [isPendingSave, setIsPendingSave] = useState(false);
    const [snackBar, setSnackBar] = useState({ isOpen: false, severity: 'success' })
    const [reasons, setReasons] = useState([{
        code: 'R001',
        label: 'LAB'
    }, {
        code: 'R002',
        label: 'ANNUAL CHECK'
    }, {
        code: 'R003',
        label: 'SICK'
    }]);

    const [currentDate, setCurrentDate] = useState(moment().format(MUI_DATE_TIME_PICKER_FORMAT));
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    const handleMembershipChange = (event) => {
        setCanSearch(event.target.value.length > 0);
        setData({ ...data, membershipId: event.target.value })

        //setMemberStatus('No Member Selected');
        setIsValidMember(false)
        setCurrentDate(moment().format(MUI_DATE_TIME_PICKER_FORMAT));
    }

    const handleValidationClick = () => {
        /* TODO: add detailed information on response*/
        setIsSaving(true);
        setSnackBar({ isOpen: false, severity: snackBar.severity });
        getAxiosInstance(authToken).get(`${environment().functionsURL}validate-member/?membershipId=${data.membershipId}`)
            .then(res => {
                const memberInfo = res.data ? res.data : {};

                setIsValidMember(memberInfo.membershipId  && memberInfo.membershipId > 0 ? true : false);
                setIsPendingSave(true);

                //setMemberStatus(memberInfo.isActive ? VALID_MEMBER : INVALID_MEMBER);
                setData({ ...memberInfo});
                setCurrentDate(moment().format(MUI_DATE_TIME_PICKER_FORMAT));

                if (memberInfo.membershipId) {
                    if (memberInfo.startDate > currentDate) {
                        setSnackBar({
                            isOpen: true,
                            severity: 'warning',
                            message: INVALID_BY_DATE
                        });
                    }
                    else {
                        setSnackBar({
                            isOpen: true,
                            severity: memberInfo.isActive ? 'success' : 'error',
                            message: memberInfo.isActive ? VALID_MEMBER : INACTIVE_MEMBER
                        });
                    }
                }
                else {
                    setSnackBar({
                        isOpen: true,
                        severity: 'warning',
                        message: INVALID_MEMBER
                    });
                }

                setIsSaving(false);
            })
            .catch(e => {

                /*if (e.error.code === 4001) {
                    setMemberStatus(e.error.message);
                }
                else {*/
                //setMemberStatus('');
                setSnackBar({
                    isOpen: true,
                    severity: 'error',
                    message: ERROR_MSG
                })
                setIsSaving(false);
                setIsPendingSave(false);
                setData(initialState)
                //}
            });
    }

    const handleReturn = (event) => {
        if (event.key === 'Enter' && canSearch) {
            handleValidationClick();
        }
    }

    useEffect(() => {
        if(authToken) {
            getAxiosInstance(authToken).get(`${environment().functionsURL}get-drop-down/visits`)
            .then(res => {
                setReasons(res.data);
            })
            .catch(e => {
                console.log(e);
            })
        }
    }, [authToken]);

    const handleSaveClick = () => {
        setIsSaving(true);
        saveCenterVisit({
            payload: {
                centerId: props.centerId,
                membershipId: data.membershipId,
                dateOfVisit: moment(currentDate, MUI_DATE_TIME_PICKER_FORMAT).toDate(),
                visitTypeId: data.visitTypeId?.code,
                isValid: data.isActive,
                comment: data.comments
            }
        }, authToken).then(success => {
            setSnackBar({
                isOpen: true,
                severity: 'success',
                message: 'Visit details saved...'
            });

            setData(initialState);
            setIsPendingSave(false);
            setIsSaving(false);
        }).catch(failure => {
            setSnackBar({
                isOpen: true,
                severity: 'error',
                message: 'Unable to save visit details, please contact support'
            });

            setIsSaving(false);
        });
    }

    const handleVisitDateChange = (event) => {
        setCurrentDate(event.target.value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar({ isOpen: false, severity: snackBar.severity });
    };

    const preventNegative = (event) => {
        if(event.key === '-'){
            event.preventDefault();
        }
    }

    return (
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <SearchIcon />
                </Avatar>
                <Typography className={classes.label} component="h1" variant="h5">
                    Member Lookup
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} >
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="membershipId"
                                    label="Membership Number"
                                    name="Membership"
                                    error={!data.membershipId || data.membershipId < 0}
                                    helperText={!data.membershipId && 'Please select a value' || (data.membershipId < 0 && 'Please add a number greater than 0')}
                                    onKeyDown={preventNegative}
                                    onKeyUp={handleReturn}
                                    onChange={handleMembershipChange}
                                    value={data.membershipId || ''}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="delete" onClick={handleValidationClick} disabled={!canSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="plan"
                                    label="Plan"
                                    name="plan"
                                    disabled
                                    value={data?.plan || ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="startDate"
                                    label="Effective Date"
                                    name="startDate"
                                    disabled
                                    value={data?.startDate || ''}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <MemberTable columns={columns} rows={data ? data.members : []}></MemberTable>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <FormControl variant="outlined" className={classes.formControl}>
                            <Typography className={classes.vrificationLabel} component="h1" variant="h5">
                                {memberStatus}
                            </Typography>
                        </FormControl> */}
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Autocomplete
                                options={reasons}
                                getOptionLabel={(option) => option.label}
                                value={data.visitTypeId}
                                style={{ width: 300 }}
                                onChange={(evt, selectedData) => setData({ ...data, visitTypeId: selectedData })}
                                renderInput={(params) => <TextField
                                    required={!data.visitTypeId}
                                    error={!data.visitTypeId}
                                    helperText={!data.visitTypeId && 'Please select a value'}
                                    {...params}
                                    label="Reason for the Visit" variant="outlined" />}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                id="datetime-local"
                                label="Visit Date"
                                type="datetime-local"
                                defaultValue={currentDate}
                                variant="outlined"
                                className={classes.textField}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleVisitDateChange}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                id="filled-multiline-flexible"
                                label="Comments"
                                multiline
                                rows={11}
                                rowsMax={11}
                                value={data.comments}
                                onChange={(event) => setData({ ...data, comments: event.target.value })}
                                variant="outlined"
                                disabled={!isValidMember && !isPendingSave}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Button variant="contained" color="primary" disabled={(!isValidMember || isSaving || !isPendingSave || !data.visitTypeId)} onClick={handleSaveClick}>Save</Button>
                        </FormControl>
                    </Grid>
                </Grid>
                {snackBar.isOpen && (
                <Snackbar open={snackBar.isOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={snackBar.severity}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>)}
            </div>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(MembershipValidation);