import {COLOR_PALETTE} from "./colorPalette";
import {createMuiTheme} from '@material-ui/core/styles';

const BLACK = '#000000';
const WHITE = '#FFFFFF';

export const theme = createMuiTheme({
    typography: {
        fontFamily: ['muli','Helvetica','Arial','Lucida','sans-serif'].join(',')
      },
      palette: {
          common: {
              black: BLACK,
              white: WHITE
          },
          background: {
              paper: WHITE,
              default: WHITE
          },
          primary: {
              light: COLOR_PALETTE.green.opacity50,
              main: COLOR_PALETTE.green.opacity100,
              dark: COLOR_PALETTE.green.opacity100,
              contrastText: WHITE
          },
          secondary: {
            light: COLOR_PALETTE.orange.opacity50,
            main: COLOR_PALETTE.orange.opacity100,
            dark: COLOR_PALETTE.orange.opacity100,
            contrastText: WHITE
          },
          error: {
            light: COLOR_PALETTE.red.opacity50,
            main: COLOR_PALETTE.red.opacity100,
            dark: COLOR_PALETTE.red.opacity100,
            contrastText: WHITE
          },
          text: {
            primary: COLOR_PALETTE.green.opacity100,
            secondary: COLOR_PALETTE.orange.opacity100,
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
          }
      }
})