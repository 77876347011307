import './App.css'
import React, { Fragment, useState, useEffect} from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import routes from "../../config/Routes"
import Navigation from "../../components/Navigation/Navigation"
import CenterVisit from '../../features/CenterVisit'
import { getCookie, setCookie, CENTER_ID_COOKIE, CENTER_NAME_COOKIE } from '../../utils/Cookies';
import {setAccountInfo} from "../../redux/middleware/AccountInfoMiddleware";
import {connect} from 'react-redux';

const App = (props) => {

  const {logout, accountInfo, setAccountInfo} = props;

  useEffect(() => {
    setAccountInfo(accountInfo)
  }, [accountInfo])

  const [centerInfo, setCenterInfo] = useState({
    centerId: getCookie(CENTER_ID_COOKIE),
    centerName: getCookie(CENTER_NAME_COOKIE)
  });

  const setCenterInfoAndCookie = (centerInfo) => {
    setCenterInfo(centerInfo);
    setCookie(CENTER_ID_COOKIE, centerInfo.centerId);
    setCookie(CENTER_NAME_COOKIE, centerInfo.centerName);
  }

  return <Fragment>
    <Router>
      <Navigation routes={routes} centerInfo={centerInfo} logout={logout}>
        <CenterVisit centerId={centerInfo.centerId} setCenterInfo={setCenterInfoAndCookie}></CenterVisit>
      </Navigation>
    </Router>
  </Fragment>
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAccountInfo: () => dispatch(setAccountInfo(ownProps.accountInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);