export const CENTER_ID_COOKIE = 'center-id';
export const CENTER_NAME_COOKIE = 'center-name';

export const getCookie = (cookieId) => {
    const cookies = document.cookie.split(';');
    const cookie = cookies.find(cookie => cookie.includes(cookieId));

    return cookie?.split('=')[1];
}

export const setCookie = (cookieId, value) => {
    document.cookie = `${cookieId}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
}