// import ApplicationsList from "./../features/Application/ApplicationsList";
// import NewApplication from "./../features/Application/NewApplication/NewApplication";
// import CommissionsList from "./../features/Commission/CommissionsList";

const routes = [
    // {
    //     linkName: 'Home',
    //     path: '/',
    //     component: <NewApplication />,
    //     showInNavigation: true,
    //     icon: <Home />
    // },
    // {
    //     linkName: 'New Application',
    //     path: '/new',
    //     component: <NewApplication />,
    //     showInNavigation: true,
    //     icon: <PersonAdd />
    // },
    // {
    //     linkName: 'Applications',
    //     path: '/applications',
    //     component: <ApplicationsList />,
    //     showInNavigation: true,
    //     icon: <ListAlt />
    // },
    // {
    //     linkName: 'Commissions',
    //     path: '/commissions',
    //     component: <CommissionsList />,
    //     showInNavigation: true,
    //     icon: <MonetizationOn />
    // },
    // {
    //     linkName: 'Signout',
    //     path: '/signout',
    //     component: <h1>Signout</h1>,
    //     showInNavigation: true,
    //     icon: <ExitToApp />
    // }
]

export default routes