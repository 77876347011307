import {
    Box
} from '@material-ui/core';
import CenterData from './CenterData';
import MembershipValidation from './MembershipValidation';

const CenterVisit = (props) => {
    return <Box width="100%">
        {props.centerId && props.centerId.length > 0 ? <MembershipValidation centerId={props.centerId}></MembershipValidation> : <CenterData onCenterChange={props.setCenterInfo}></CenterData>}
    </Box>
}

export default CenterVisit;