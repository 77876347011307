const local = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "http://localhost:3000",
    centerGroupId: "e1b25668-e9c1-4713-af2b-6e573aad2b37"
}

const dev = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-center-webapp.azurewebsites.net",
    centerGroupId: "e1b25668-e9c1-4713-af2b-6e573aad2b37"
}

const test = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-center-webapp.azurewebsites.net",
    centerGroupId: "e1b25668-e9c1-4713-af2b-6e573aad2b37"
}

const prod = {
    functionsURL: "https://prod-omp-functionapp.azurewebsites.net/api/",
    azureADRedirectURI: "https://prod-omp-center-webapp.azurewebsites.net",
    centerGroupId: "354cf3a4-d25a-4376-9880-0828e59011c3"
}

const environment = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'PROD':
            return prod;
        case 'TEST':
            return test;
        case 'DEV':
            return dev;
        case 'LOCAL':
            return local;
        default:
            return dev;
    }
};

export default environment;