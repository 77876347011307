import {
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar
} from '@material-ui/core';
import {getAxiosInstance} from "../config/axios";
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../components/Alert';
import environment from "../config/environment";
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: theme.spacing(10),
        height: 200,
        width: "100%"
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: '40%',
    },
}));

const CenterData = (props) => {

    const {accountInfo} = props;
    const classes = useStyles();
    const [centers, setCenters] = useState([]);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(authToken) {
            getAxiosInstance(authToken).get(`${environment().functionsURL}get-drop-down/centers`)
            .then(centers => {
                setCenters(centers.data);
            })
            .catch(e => setIsSnackbarOpen(true));
        }
    }, [authToken]);

    const updateCenter = (event) => {
        const centerId = event.target.value;
        const centerName = centers.find(c => c.code === centerId).label;
        props.onCenterChange({
            centerId: centerId,
            centerName: centerName
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen(false)
    };

    return <Box className={classes.root}>
        <div className={classes.paper}>
            <Typography variant="h5">Please select a Center before contiuing</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="label-center-select">Center</InputLabel>
                <Select
                    labelId="label-center-select"
                    id="select-center"
                    onChange={updateCenter}
                    label="Reason for the Visit"
                >
                    {centers?.map(c => (
                        <MenuItem key={c.label} value={c.code}>{c.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
        <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="Error">
                Error trying to load centers
            </Alert>
        </Snackbar>
    </Box>;
}
const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(CenterData);