import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const saveCenterVisit = (centerVisit, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken)({
            method: 'post',
            url: `${environment().functionsURL}center-visit/`,
            data: centerVisit
        })
        .then(response => {
            if(response && response.status === 200) {
                resolve(response);
            }
        })
        .catch(error => reject(error))
    })
}