import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Grid
} from '@material-ui/core';
import companyLogo from "../../assets/media/companyLogo.png";
import stethoscope from "../../assets/media/stethoscope.png";
import { COLOR_PALETTE } from "../../config/colorPalette";
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: COLOR_PALETTE.orange.opacity100
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    overflowY: 'hidden',
    marginTop: 65,
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: COLOR_PALETTE.green.opacity100
  },
  agentInfo: {
    color: COLOR_PALETTE.green.opacity100,
    //border: `2px solid ${COLOR_PALETTE.orange.opacity100}`,
    float: 'right',
    //borderRadius: '3px',
    padding: '2px 6px',
    fontSize: 'sm',
    verticalAlign: 'middle'
  },
  padded: {
    paddingTop: theme.spacing(1)
  },
  stethoscope: {
    color: COLOR_PALETTE.green.opacity100,
    //border: `2px solid ${COLOR_PALETTE.orange.opacity100}`,
    float: 'right',
    //borderRadius: '3px',
    padding: '2px 6px',
    verticalAlign: 'middle'
  }
}));

const Navigation = (props) => {
  const classes = useStyles();
  const [open] = React.useState(false);

  const { children, logout  } = props



  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={6}><Typography variant="h6" noWrap>
              <img src={companyLogo} alt="Company Logo" height="40" />
            </Typography></Grid>
            <Grid item xs={4} className={classes.padded}>
              <img src={stethoscope} className={classes.agentInfo} alt="Company Logo" height="25" />
              <Typography className={classes.agentInfo}>{props.centerInfo.centerName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography 
              style={{marginTop: '10px', marginLeft: '12px'}}
              >
                  <Link
                    to={location => location}
                    style={{ textDecoration: 'none', color: 'green' }}
                    onClick={logout}
                    >
                      Logout
                  </Link>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
}

export default Navigation
